/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAppConfig } from '../../store/actions/authActionCreator';

import {
    Button,
    Paper,
    Box,
    Typography,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import Searchbar from '../../components/Searchbar';
import { Spinner } from '../../components/UI';
import EmptyStates from '../../components/EmptyStates';
import { getActiveInitRoute, getPermissions } from '../../utils/helper';
import { EmptyIcon } from '../../assets/svg';
import { HireTableItem, ConvertToStaffCTA } from '.';
import {
    fetchAllLeadReport,
    fetchAllLeads,
} from '../../store/actions/leadActionCreator';
import LeadModal from '../../components/Modals/LeadModal';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../components/Modals/FilterModal';
import { GetCities } from '../../store/actions/settingsActionCreator';
import UploadLeadModal from '../../components/Modals/UploadLeadModal';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import ConvertToStaffModal from './components/ConvertToStaffModal';
import { useDebounce } from '../../hooks/useDebounce';
const useStyles = makeStyles((theme) => ({
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    verticalCardContents: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: 'solid 1px #ebedf8',
        borderRadius: '3.8px',
        backgroundColor: '#ebedf8',
        paddingLeft: '16px',
        height: '88px',
        margin: '0 8px 0 0 ',
    },
    verticalCardContentsActive: {
        backgroundColor: '#d1d8ff',
    },
    verticalCardContentsNoMargin: {
        margin: 0,
    },
}));

const LeadStatuses = [
    { label: 'Open', key: 'openCount', value: 'Open' },
    { label: 'Completed', key: 'completedCount', value: 'Completed' },
    { label: 'Resubmitted', key: 'resubmittedCount', value: 'Resubmitted' },
    {
        label: 'Marked Incomplete',
        key: 'markedIncompleteCount',
        value: 'Marked Incomplete',
    },
    // { label: 'Incomplete', key: 'inCompleteCount', value: 'Incomplete' },
    { label: 'Verified', key: 'verifiedCount', value: 'Verified' },
    { label: 'Onboarded', key: 'onboardedCount', value: 'Onboarded' },
    { label: 'FOD', key: 'convertedCount', value: 'Converted' },
    { label: 'Rejected', key: 'rejectedCount', value: 'Rejected' },
    { label: 'On Hold', key: 'onHoldCount', value: 'On Hold' },
];

const LeadSourceNames = [
    { label: 'All', key: 'all', value: 'All' },
    { label: 'Referral', key: 'Referral', value: 'Referral' },
    { label: 'Self Onboarded', key: 'Self_Onboarded', value: 'Self Onboarded' },
];

const joiningFeeStatuses = [
    { label: 'All', key: 'all', value: 'All' },
    { label: 'Paid', key: 'paid', value: 'Paid' },
    { label: 'Not Paid', key: 'notPaid', value: 'Not Paid' },
];

const Hire = ({
    user,
    lead,
    cityList,
    configs,
    dispatchFetchAllLeads,
    dispatchGetCities,
    dispatchFetchAppConfig,
    dispatchFetchAllLeadReport,
    ...props
}) => {
    const { debounce, isBouncing } = useDebounce();
    const classes = useStyles();

    const [page, setPage] = React.useState(props.location?.state?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        props?.location?.state?.rowsPerPage ?? 10,
    );

    const [firstRenderDone, setFirstRenderDone] = useState(false);

    const [leadModalOpen, setLeadModalOpen] = React.useState(false);
    const [earningsModalOpen, setEarningsModalOpen] = useState(false);
    const [bulkUploadModal, setbulkUploadModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchVal, setSearchVal] = useState(
        props.location?.state?.searchVal ?? '',
    );
    const [permissions, setPermissions] = useState(getPermissions('hire'));

    const [filteredValues, setFilteredValue] = useState({
        leadDate: {
            startDate: moment().startOf('year')?.valueOf(),
            endDate: moment().endOf('year')?.valueOf(),
        },
        startDate: moment().startOf('year')?.format('YYYY-MM-DD'),
        endDate: moment().endOf('year')?.format('YYYY-MM-DD'),
        leadSourceName: LeadSourceNames[0].value,
        joiningFeeStatus: 'All',
    });
    const [leadListingPayload, setLeadListingPayload] = React.useState({
        filterStatus: 'Open',
        pageNo: page + 1,
        pageSize: rowsPerPage,
        searchText: searchVal,
        ...filteredValues,
    });
    const [selected, setSelected] = React.useState([]);
    const [modalSelected, setModalSelected] = React.useState([]);
    const [convertToStaffModalOpen, setConvertToStaffModalOpen] =
        useState(false);
    const handleConvertToStaff = () => {
        if (selected && selected.length > 0) {
            setModalSelected(selected);
            setSelected([]);
            setConvertToStaffModalOpen(true);
        }
    };
    const prepareFilterOptions = () => {
        return [
            {
                type: FILTER_INPUT_TYPE.DATERANGE,
                label: 'Lead Date',
                name: 'leadDate',
                value: filteredValues?.leadDate ?? undefined,
                onChange: (e) => {
                    console.log('eeeeeeeee', e);
                    setFilteredValue({
                        ...filteredValues,
                        leadDate: e,
                        startDate: moment(e.startDate).format('YYYY-MM-DD'),
                        endDate: moment(e.endDate).format('YYYY-MM-DD'),
                    });
                },
                render: () => {
                    if (
                        moment(
                            filteredValues?.leadDate?.startDate,
                        )?.valueOf() == moment().startOf('year')?.valueOf() &&
                        moment(filteredValues?.leadDate?.endDate)?.valueOf() ==
                            moment().endOf('year')?.valueOf()
                    )
                        return `Date: This Year`;
                    else
                        return `Date: ${filteredValues.startDate} - ${filteredValues.endDate}`;
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'City',
                name: 'city',
                value: filteredValues?.cityId,
                options: cityList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        city: e?.target?.value?.name,
                        cityId: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Delivery Preference',
                name: 'deliveryPreference',
                value: filteredValues?.deliveryPreferenceId,
                options: user?.config?.riderDeliveryPreference?.map((item) => ({
                    label: item.value,
                    value: item.id,
                    ...item,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        deliveryPreference: e?.target?.value?.value,
                        deliveryPreferenceId: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.SELECT,
                label: 'Vehicle Type',
                name: 'vehicleType',
                value: filteredValues?.vehicleTypeId,
                options: user?.config?.riderVehicleTypes?.map((item) => ({
                    label: item.value,
                    value: item.id,
                    ...item,
                })),
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        vehicleType: e?.target?.value?.value,
                        vehicleTypeId: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.RADIOGROUP,
                label: 'Lead Source',
                name: 'leadSourceName',
                value: filteredValues?.leadSourceName,
                options: LeadSourceNames,
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        leadSourceName: e?.target?.value,
                    });
                },
            },
            {
                type: FILTER_INPUT_TYPE.RADIOGROUP,
                label: 'Joining Fee Status',
                name: 'joiningFeeStatus',
                value: filteredValues?.joiningFeeStatus,
                options: joiningFeeStatuses,
                onChange: (e) => {
                    setFilteredValue({
                        ...filteredValues,
                        joiningFeeStatus: e?.target?.value,
                    });
                },
            },
        ];
    };

    useEffect(() => {
        setFirstRenderDone(true);
        if (!permissions.read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
        dispatchGetCities();
        dispatchFetchAppConfig();
    }, []);

    useEffect(() => {
        setPermissions(getPermissions('hire'));
        if (!getPermissions('hire').read) {
            props.history.push(`/${getActiveInitRoute()}`);
        }
    }, [configs]);

    const handleSelect = (data) => {
        if (data && data.id) {
            const currentIndex = selected.indexOf(data.id);
            const newChecked = [...selected];

            if (currentIndex === -1) {
                newChecked.push(data.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setSelected(newChecked);
        }
    };
    const handleAllSelect = (all, data) => {
        if (all) {
            if (data && data.length > 0) {
                const newChecked = [];
                for (let index = 0; index < data.length; index++) {
                    if (index === 50) {
                        break;
                    }
                    const element = data[index];
                    if (element && element.id) {
                        newChecked.push(element.id);
                    }
                }
                setSelected(newChecked);
            }
        } else {
            setSelected([]);
        }
    };
    const fetchAllHireInfo = async (payload = {}) => {
        await setLoading(true);
        dispatchFetchAllLeads(
            payload,
            (resp) => {
                setLoading(false);
                console.log('hire data', resp);
            },
            (resp) => {
                setLoading(false);
                console.log('hire data err', resp);
            },
        );
    };

    const handleChangePage = (event, newPage) => {
        console.log('SETPAGE ', newPage);
        setPage(newPage);
        setLeadListingPayload((prev) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLeadListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event.target.value,
            };
        });
    };

    const onFilterApplied = () => {
        setPage(0);
        setLeadListingPayload((prev) => {
            return {
                ...prev,
                pageNo: 1,
                ...filteredValues,
            };
        });
    };

    React.useEffect(() => {
        console.log('leadListingPayload changed', leadListingPayload);
        fetchAllHireInfo(leadListingPayload);
    }, [leadListingPayload]);

    React.useEffect(() => {
        console.log('getting listing', leadListingPayload);
        if (firstRenderDone)
            setLeadListingPayload((prev) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchVal,
                };
            });
    }, [searchVal]);

    React.useEffect(() => {
        console.log('changed filteredValues', filteredValues);
    }, [filteredValues]);

    return (
        <div>
            <Spinner loading={loading}>
                <Paper className="cardStyles bottom-mar" elevation={0}>
                    <Box className="horizontalCardContents">
                        {LeadStatuses.map((status, index) => (
                            <Box
                                className={`${classes.verticalCardContents} ${
                                    leadListingPayload.filterStatus ===
                                        status.value &&
                                    classes.verticalCardContentsActive
                                }`}
                                style={{
                                    width:
                                        100 / (LeadStatuses.length + 1) + '%',
                                }}
                                onClick={() => {
                                    setPage(0);
                                    setLeadListingPayload((prev) => {
                                        return {
                                            ...prev,
                                            filterStatus: status.value,
                                            pageNo: 1,
                                            pageSize: rowsPerPage,
                                            searchText: searchVal,
                                        };
                                    });
                                }}
                            >
                                <Typography className={classes.cardHeader}>
                                    {status.label}
                                </Typography>
                                <Typography className={classes.cardSubHeader}>
                                    {lead?.[status.key] ?? 0}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Paper>

                <Paper style={{ padding: '12px 0' }} elevation={0}>
                    <Box
                        className="horiz-end"
                        style={{
                            margin: '24px 16px 16px 24px',
                        }}
                    >
                        <Box
                            style={{
                                width: '400px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Searchbar
                                placeHolderText="Search by Name, City Name, Contact No."
                                // placeHolderStyles={{
                                //     color: '#979797',
                                // }}
                                backendSearchbar={true}
                                onBackendSearch={(searchText) => {
                                    setPage(0);
                                    console.log(
                                        'leadListingPayload',
                                        leadListingPayload,
                                    );
                                    setSearchVal(searchText);
                                }}
                                value={searchVal}
                                keys={['name', 'hubName']}
                                onFilter={async (filteredData) => {}}
                                onChange={(value) => {
                                    console.log('blur', value);
                                    setSearchVal(value);
                                }}
                            />
                            <FilterModal
                                filterOptions={prepareFilterOptions()}
                                filteredValues={filteredValues}
                                onFilterApplied={onFilterApplied}
                            />
                        </Box>

                        <Box>
                            {leadListingPayload &&
                                leadListingPayload.filterStatus &&
                                [
                                    'Resubmitted',
                                    'Marked Incomplete',
                                    'Verified',
                                ]?.includes(
                                    leadListingPayload?.filterStatus,
                                ) && (
                                    <Tooltip
                                        title="Download Report"
                                        placement="top"
                                    >
                                        <IconButton
                                            className={'inputbutton'}
                                            variant="outlined"
                                            style={{
                                                marginRight: 8,
                                                border: '1px solid #43419e',
                                                borderRadius: 8,
                                                padding: 6,
                                            }}
                                            color="primary"
                                            onClick={(e) => {
                                                debounce(() => {
                                                    const {
                                                        pageNo,
                                                        pageSize,
                                                        ...payload
                                                    } = leadListingPayload;
                                                    dispatchFetchAllLeadReport(
                                                        { ...payload },
                                                        (response) => {
                                                            console.log(
                                                                'GetLeadReport Success',
                                                                response,
                                                            );
                                                            if (
                                                                response
                                                                    ?.response
                                                                    ?.s3FileUrl
                                                            ) {
                                                                window.location.href =
                                                                    response?.response?.s3FileUrl;
                                                            }
                                                        },
                                                        (response) => {
                                                            console.log(
                                                                'GetLeadReport FAILED',
                                                                response,
                                                            );
                                                        },
                                                    );
                                                });
                                            }}
                                            disabled={
                                                isBouncing ||
                                                !lead?.lineItems?.length
                                            }
                                        >
                                            <GetAppIcon size="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            {leadListingPayload &&
                                leadListingPayload.filterStatus &&
                                leadListingPayload.filterStatus ===
                                    'Verified' && (
                                    <ConvertToStaffCTA
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleConvertToStaff();
                                        }}
                                        disabled={
                                            permissions &&
                                            permissions.create &&
                                            selected &&
                                            selected.length > 0
                                                ? false
                                                : true
                                        }
                                        disableElevation
                                    >
                                        {selected && selected.length > 0
                                            ? 'Convert to Staff'
                                            : 'Select to Convert'}
                                    </ConvertToStaffCTA>
                                )}
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-pos"
                                onClick={() => {
                                    // toggleStaffModal();
                                    setbulkUploadModal(true);
                                }}
                                disabled={!permissions.create}
                                disableElevation
                            >
                                Bulk Upload
                            </Button>
                            {bulkUploadModal && (
                                <UploadLeadModal
                                    enumType="bulkUpload"
                                    open={bulkUploadModal}
                                    onClose={() => {
                                        // console.log('Closed');
                                        setbulkUploadModal(false);
                                    }}
                                />
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-pos"
                                onClick={() => {
                                    // toggleStaffModal();
                                    setEarningsModalOpen(true);
                                }}
                                disabled={!permissions.create}
                                disableElevation
                            >
                                Upload Leads
                            </Button>
                            {earningsModalOpen && (
                                <UploadLeadModal
                                    enumType="earning"
                                    open={earningsModalOpen}
                                    onClose={() => {
                                        // console.log('Closed');
                                        setEarningsModalOpen(false);
                                    }}
                                />
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-pos"
                                onClick={() => {
                                    // toggleStaffModal();
                                    setLeadModalOpen(true);
                                }}
                                disabled={true || !permissions.create}
                                disableElevation
                            >
                                Add Lead
                            </Button>
                            {leadModalOpen && (
                                <LeadModal
                                    open={leadModalOpen}
                                    leadInfo={{}}
                                    onClose={() => {
                                        console.log('Closed');
                                        setLeadModalOpen(false);
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <FilteredState
                        filterOptions={prepareFilterOptions()}
                        filteredValues={filteredValues}
                        count={lead?.totalFilteredCount ?? 0}
                        selected={selected}
                        selectInfo={
                            leadListingPayload &&
                            leadListingPayload.filterStatus &&
                            leadListingPayload.filterStatus === 'Verified'
                        }
                        onDelete={(itemKey) => {
                            let tempfilteredValues = {
                                ...filteredValues,
                            };
                            delete tempfilteredValues?.[itemKey];
                            if (itemKey === 'leadDate') {
                                delete tempfilteredValues['startDate'];
                                delete tempfilteredValues['endDate'];
                            }
                            setFilteredValue(tempfilteredValues);
                            let templeadListingPayload = {
                                ...leadListingPayload,
                            };
                            delete templeadListingPayload?.[itemKey];
                            if (itemKey === 'leadDate') {
                                delete templeadListingPayload['startDate'];
                                delete templeadListingPayload['endDate'];
                            }
                            setLeadListingPayload({
                                ...templeadListingPayload,
                                ...tempfilteredValues,
                            });
                        }}
                    />
                    {lead?.totalCount === 0 || lead?.openCount === 0 ? (
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyIcon />
                        </Box>
                    ) : (
                        <Box style={{ margin: '0 24px 0 24px' }}>
                            <HireTableItem
                                fetchAllHireInfo={() => {
                                    fetchAllHireInfo(leadListingPayload);
                                }}
                                selected={selected}
                                permissions={permissions}
                                data={lead.lineItems}
                                onSelectItem={handleSelect}
                                onSelectAll={handleAllSelect}
                                disableSelect={
                                    modalSelected &&
                                    modalSelected.length === 0 &&
                                    !convertToStaffModalOpen
                                        ? false
                                        : true
                                }
                                leadListingPayload={leadListingPayload}
                            />
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100]}
                                component="div"
                                count={lead?.totalFilteredCount ?? 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                            {convertToStaffModalOpen && (
                                <ConvertToStaffModal
                                    open={convertToStaffModalOpen}
                                    selected={modalSelected}
                                    onClose={() => {
                                        fetchAllHireInfo(leadListingPayload);
                                        setModalSelected([]);
                                        setSelected([]);
                                        setConvertToStaffModalOpen(false);
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Paper>
            </Spinner>
        </div>
    );
};

const mapStateToProps = (state) => ({
    lead: state?.lead,
    cityList: state.settings?.cities ?? [],
    user: state.user,
    configs: state.user.config,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAllLeads: (payload, onSuccess, onError) =>
        dispatch(fetchAllLeads(payload, onSuccess, onError)),
    dispatchFetchAllLeadReport: (payload, onSuccess, onError) =>
        dispatch(fetchAllLeadReport(payload, onSuccess, onError)),
    dispatchGetCities: (payload, onSuccess, onError) =>
        dispatch(GetCities(payload, onSuccess, onError)),

    dispatchFetchAppConfig: (onSuccess, onError) =>
        dispatch(fetchAppConfig(onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hire);
